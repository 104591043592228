import React, { useRef, useState, useContext } from "react";
import styled from "styled-components";
import { CustomEase } from "gsap/CustomEase";
import loadable from '@loadable/component'
import { StaticImage } from "gatsby-plugin-image";

import useMedia from "@hooks/useMedia";

import colors from "@styles/colors";
import text from "@styles/text";
import media from "@styles/media";
import { OpenFormContext } from "@components/Layout";

const Animation = loadable(() => import("@components/Animation"));
const PrimaryButton = loadable(() => import("@components/PrimaryButton"));

CustomEase.create("bgImg", "0.87, 0, 0.05, 1");

type props = {
  data: any
}

const Hero: React.FC<props> = ({ data }) => {
  const bgImageRef = useRef(null);
  const contentRef = useRef(null);
  const wrapperRef = useRef(null);
  const title1Ref = useRef(null);

  const openForm = useContext(OpenFormContext);

  const title1Text = useMedia(
    "Access\nunprecedented\namounts of data\non your pipeline",
    "Access\nUnprecedented\nAmounts of Data\non Your Pipeline",
    "Access\nUnprecedented\nAmounts of Data\non Your Pipeline",
    "Access\nUnprecedented\nAmounts of\nData on Your\nPipeline"  
  );

  return (
    <Wrapper ref={wrapperRef}>
      <AnimationWrapper>
        <Animation />
        <Gradient />
        <Gradient2/>
      </AnimationWrapper>
      <Top>
        <BackgroundImgContainer>
          <BackgroundImg ref={bgImageRef}>
            <StaticImage
              src={"../../images/jpg/heroBG1.jpg"}
              alt="pipelines"
              placeholder="blurred"
              objectFit="cover"
              style={{width: '100%', height: '100%'}}
            />
          </BackgroundImg>
        </BackgroundImgContainer>
        <Content ref={contentRef}>
          <TitleXL ref={title1Ref}>{title1Text}</TitleXL>
          <PrimaryButton
            text="Request a Demo"
            textColor={colors.black}
            backgroundColor={colors.keppel100}
            hoverColor={colors.keppel60}
            onClick={() => openForm(true)}
            width={useMedia("fit-content", "fit-content", "fit-content", "100%")}
          />
        </Content>
      </Top>
    </Wrapper>
  );
};

export default Hero;

const Wrapper = styled.section`
  position: relative;
  // background-color: ${colors.black};
  width: 100vw;
`;

const AnimationWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vw;
`

const Top = styled.div`
  position: relative;
  height: 54.722vw;

  ${media.tablet} {
    height: 142.994vw;
  }

  ${media.mobile} {
    height: 216.533vw;
  }
`

const BackgroundImgContainer = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 52.5%;
  top: 0;
  left: 0;
  
  ${media.tablet} {
    width: 100%;
    height: 92.336vw;
    top: unset;
    bottom: 0;
  }

  ${media.mobile} {
    width: 100%;
    height: 60%;
    top: unset;
    bottom: 0;
  }
`;

const BackgroundImg = styled.div`
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;

  ${media.tablet} {
    object-position: center center;
  }
`;

const Content = styled.div`
  position: absolute;
  z-index: 5;

  top: 14.236vw;
  left: 42.708vw;

  ${media.tablet} {
    left: 5.995vw;
    top: 25.54vw;
  }

  ${media.mobile} {
    left: 3.667vw;
    top: 57vw;
  }
`;

const TitleXL = styled.h1`
  color: ${colors.culturedWhite60};
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;

  ${text.desktopXLHeading}
  margin-bottom: 3.472vw;
  width: 54.861vw;

  ${media.tablet} {
    ${text.tabletXLHeading}
    margin-bottom: 5.995vw;
    width: 88.01vw;
  }

  ${media.mobile} {
    ${text.mobileSHeading}
    margin-bottom: 13.333vw;
    width: 91.4vw;
  }
`;

const Gradient = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(${colors.black}, rgba(0, 0, 0, 0));
  height: 50%;

  ${media.tablet} {
    height: 40%;
  }
`;

const Gradient2 = styled(Gradient)`
  bottom: 0;
  top: unset;
  height: 50%;
  background: linear-gradient(rgba(0, 0, 0, 0), ${colors.black});
`
